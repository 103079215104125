import React from "react"
import "twin.macro"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Anchor from "@components/Anchor"
import Section from "@components/section"
import Container from "@components/container"
import StaticHero from "@components/staticHero"
import { narcolepsySitemap, ihSitemap } from "@utils/sitemapData"

const SiteMapShared = ({ location }) => {
  const LinkList = ({ links }) => {
    return (
      <ul tw="pl-7 [li]:last:mb-0">
        {links.map((link, i) => {
          return link.subLinks ? (
            <li key={i} tw="font-black mb-4 lg:mb-8">
              <span tw="block mb-2">{link.text}</span>
              <ul tw="font-normal">
                {link.subLinks &&
                  link.subLinks.map((sublink, i) => {
                    return (
                      <li key={i} tw="py-2 ml-6">
                        <Anchor colour="merlin" link={sublink.url}>
                          {sublink.text}
                        </Anchor>
                      </li>
                    )
                  })}
              </ul>
            </li>
          ) : (
            <li key={i} tw="font-black mb-4 lg:mb-8">
              <Anchor colour="merlin" link={link.url}>
                {link.text}
              </Anchor>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <Layout
      location={location}
      headerType="home"
      footerType="landing"
      copyright={
        <>
          &copy; 2024 Jazz Pharmaceuticals, Inc.{" "}
          <nobr>US-XYW-2200416 Rev1124</nobr>
        </>
      }
    >
      <Seo
        title="User Sitemap | XYWAV"
        description="Explore the Xywav.com sitemap. See XYWAV™ (calcium, magnesium, potassium, and sodium oxybates) oral solution Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero gradient="purple-red" alt="">
        <span tw="block (pt-6 pb-7)">Site map</span>
      </StaticHero>

      <Section tw="pt-12">
        <Container>
          <Anchor link="/" colour="merlin">
            Dual-Indication Homepage
          </Anchor>
          <p tw="font-black mt-8 mb-7 text-xl">
            <Anchor link="/narcolepsy/" colour="merlin">
              Narcolepsy
            </Anchor>
          </p>
          <LinkList links={narcolepsySitemap} />

          <p tw="font-black mt-8 mb-7 text-xl">
            <Anchor colour="merlin" link="/idiopathic-hypersomnia/">
              Idiopathic Hypersomnia (IH)
            </Anchor>
          </p>

          <LinkList links={ihSitemap} />
        </Container>
      </Section>
    </Layout>
  )
}

export default SiteMapShared
