import React from "react"

export const narcolepsySitemap = [
  {
    url: "/narcolepsy/",
    text: "Homepage",
  },
  {
    url: "/narcolepsy/living-with-narcolepsy/",
    text: "Narcolepsy & Cardiovascular Risk",
  },
  {
    text: "Why XYWAV",
    subLinks: [
      {
        url: "/narcolepsy/what-is-xywav/",
        text: "What is XYWAV",
      },
      {
        url: "/narcolepsy/lower-sodium-oxybate-treatment-option/",
        text: "Sodium in XYWAV",
      },
      {
        url: "/narcolepsy/transitioning-from-xyrem/",
        text: "Transitioning From XYREM",
      },
      {
        url: "/narcolepsy/faq/",
        text: "FAQ",
      },
    ],
  },
  {
    text: "Getting XYWAV",
    subLinks: [
      {
        url: "/narcolepsy/talk-to-your-doctor/",
        text: "Talking to Your Doctor",
      },
      {
        url: "/narcolepsy/find-a-physician/",
        text: "Find a Doctor",
      },
      {
        url: "/narcolepsy/getting-xywav/",
        text: "Getting XYWAV",
      },
      {
        url: "/narcolepsy/dosing/",
        text: "Dosing",
      },
      {
        url: "/narcolepsy/xywav-side-effects/",
        text: "Side Effects",
      },
    ],
  },
  {
    text: "Support & Resources",
    subLinks: [
      {
        url: "/narcolepsy/jazzcares-for-xywav/",
        text: (
          <>
            JazzCares<sup>&reg;</sup> Savings and Support
          </>
        ),
      },
      {
        url: "/narcolepsy/patient-resources/",
        text: "Resources and Videos",
      },
    ],
  },
  {
    url: "/patient-stories/",
    text: "Patient Stories",
  },
  {
    url: "/narcolepsy/stay-connected/",
    text: "Stay Connected",
  },
]

export const ihSitemap = [
  {
    url: "/idiopathic-hypersomnia/",
    text: "Homepage",
  },
  {
    text: "About XYWAV",
    subLinks: [
      {
        text: "What is XYWAV?",
        url: "/idiopathic-hypersomnia/what-is-xywav/",
      },
      {
        text: "Study Results with XYWAV",
        url: "/idiopathic-hypersomnia/xywav-efficacy/",
      },
      {
        text: "Possible Side Effects",
        url: "/idiopathic-hypersomnia/side-effects/",
      },
    ],
  },
  {
    text: "Treating IH",
    subLinks: [
      {
        text: "Managing IH",
        url: "/idiopathic-hypersomnia/treating-ih/",
      },
      {
        text: "Your IH Experience",
        url: "/idiopathic-hypersomnia/your-ih-experience/",
      },
    ],
  },
  {
    text: "Getting XYWAV",
    subLinks: [
      {
        text: "Talking to Your Doctor",
        url: "/idiopathic-hypersomnia/talking-to-your-doctor/",
      },
      {
        text: "Taking XYWAV",
        url: "/idiopathic-hypersomnia/dosing/",
      },
      {
        text: "Getting XYWAV",
        url: "/idiopathic-hypersomnia/getting-xywav/",
      },
    ],
  },
  {
    text: "Find a Doctor",
    subLinks: [
      {
        text: "Find a Doctor",
        url: "/idiopathic-hypersomnia/find-a-doctor/",
      },
    ],
  },
  {
    text: "XYWAV Support",
    subLinks: [
      {
        text: (
          <>
            JazzCares<sup>&reg;</sup> Savings &amp; Support
          </>
        ),
        url: "/idiopathic-hypersomnia/jazzcares-for-xywav/",
      },
      {
        text: "Resources & Videos",
        url: "/idiopathic-hypersomnia/resources/",
      },
      {
        text: "Frequently Asked Questions",
        url: "/idiopathic-hypersomnia/faq/",
      },
    ],
  },
]
